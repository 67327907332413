import type { Analytics } from '@seek/seek-jobs-analytics';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';

import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';

import type { CareerFeedMessage, ErrorType, ProgressType } from '../types';

type TrackingSelectedSuggestion = {
  label: string;
  isFreeTextInput: boolean;
};

type UseCareerFeedTrackingReturnType = {
  trackGenAIChatCtaDisplayed: () => void;
  trackGenAIChatStartPressed: () => void;
  trackGenAIChatStarted: () => void;
  trackGenAIChatResetPressed: (
    buttonPosition: 'home recs' | 'chat widget',
  ) => void;
  trackGenAIChatOutputDisplayed: (errorType?: ErrorType) => void;
  trackGenAIChatFeedbackPressed: (sentiment: boolean) => void;
  trackGenAIChatInputPressed: (
    candidateMessage: string | undefined,
    selectedSuggestions: TrackingSelectedSuggestion[],
  ) => Parameters<Analytics['homepageGenAIInputPressed']>[0] | null;
  trackGenAIChatInputSucceeded: (
    args: Parameters<Analytics['homepageGenAIInputSucceeded']>[0],
  ) => void;
  trackGenAIChatEnded: (error?: boolean) => void;
  trackRecommendedJobsRefreshed: (args: { jobsCount: number }) => void;
};

export const useCareerFeedTracking = ({
  sequence,
  sessionId,
  currentMessage,
  currentSentiment,
  progress,
}: {
  currentSentiment: boolean | null;
  sequence: number;
  sessionId: string | null;
  currentMessage?: CareerFeedMessage;
  progress: ProgressType;
}): UseCareerFeedTrackingReturnType => {
  const analyticsFacade = useAnalyticsFacade();
  const [trackingSessionId, setTrackingSessionId] = useState(v4());
  const trackedOutputDisplayed = useRef<Record<number, boolean>>({});
  const trackedChatDisplayed = useRef<boolean>(false);
  const trackedChatStarted = useRef<boolean>(false);

  useEffect(() => {
    if (!sessionId) {
      setTrackingSessionId(v4());
      trackedChatDisplayed.current = false;
      trackedChatStarted.current = false;
      trackedOutputDisplayed.current = {};
    }
  }, [sessionId]);

  const trackGenAIChatCtaDisplayed: UseCareerFeedTrackingReturnType['trackGenAIChatCtaDisplayed'] =
    useCallback(() => {
      if (!trackedChatDisplayed.current) {
        analyticsFacade.homepageGenAIChatCtaDisplayed({
          chatSessionId: trackingSessionId,
        });
        trackedChatDisplayed.current = true;
      }
    }, [analyticsFacade, trackedChatDisplayed, trackingSessionId]);

  const trackGenAIChatStartPressed: UseCareerFeedTrackingReturnType['trackGenAIChatStartPressed'] =
    useCallback(() => {
      analyticsFacade.homepageGenAIChatStartPressed({
        chatSessionId: trackingSessionId,
      });
    }, [analyticsFacade, trackingSessionId]);

  const trackGenAIChatStarted: UseCareerFeedTrackingReturnType['trackGenAIChatStarted'] =
    useCallback(() => {
      if (!trackedChatStarted.current) {
        analyticsFacade.homepageGenAIChatStarted({
          chatSessionId: trackingSessionId,
        });
        trackedChatStarted.current = true;
      }
    }, [analyticsFacade, trackedChatStarted, trackingSessionId]);

  const trackGenAIChatOutputDisplayed: UseCareerFeedTrackingReturnType['trackGenAIChatOutputDisplayed'] =
    useCallback(
      (errorType?: ErrorType) => {
        if (
          currentMessage &&
          !currentMessage.loading &&
          // output displayed with error can be triggered multiple times
          (!trackedOutputDisplayed.current[sequence] || errorType)
        ) {
          analyticsFacade.homepageGenAIChatOutputDisplayed({
            chatSessionId: trackingSessionId,
            chatOutputSequence: sequence,
            chatOutputText: errorType ? errorType : currentMessage.text,
            chatOutputType: getQuestionType(progress, errorType),
            chatOutputOptionsCount: !errorType
              ? (currentMessage.suggestions?.length ?? 0)
              : undefined,
            chatOutputOptions: !errorType
              ? (currentMessage.suggestions ?? []).map(
                  (suggestion) => suggestion.label,
                )
              : undefined,
          });

          trackedOutputDisplayed.current = {
            ...trackedOutputDisplayed.current,
            [sequence]: true,
          };
        }
      },
      [
        analyticsFacade,
        currentMessage,
        progress,
        sequence,
        trackedOutputDisplayed,
        trackingSessionId,
      ],
    );

  const trackGenAIChatFeedbackPressed = useCallback(
    (sentiment: boolean) => {
      if (currentMessage && !currentMessage.loading) {
        analyticsFacade.homepageGenAIChatFeedbackPressed({
          chatSessionId: trackingSessionId,
          chatOutputSequence: sequence,
          chatOutputText: currentMessage.text,
          chatOutputType: getQuestionType(progress),
          chatOutputOptionsCount: currentMessage.suggestions?.length ?? 0,
          chatOutputOptions: (currentMessage.suggestions ?? []).map(
            (suggestion) => suggestion.label,
          ),
          userFeedback: getFeedbackText(sentiment),
        });
      }
    },
    [analyticsFacade, currentMessage, progress, sequence, trackingSessionId],
  );

  const trackGenAIChatInputPressed = useCallback(
    (
      candidateMessage: string | undefined,
      selectedSuggestions: TrackingSelectedSuggestion[],
    ): Parameters<Analytics['homepageGenAIInputPressed']>[0] | null => {
      if (currentMessage && !currentMessage.loading) {
        const isFreeText =
          selectedSuggestions.length === 0 ||
          (selectedSuggestions.length === 1 &&
            selectedSuggestions[0].isFreeTextInput);

        const payload: Parameters<Analytics['homepageGenAIInputPressed']>[0] = {
          chatSessionId: trackingSessionId,
          chatOutputSequence: sequence,
          chatOutputText: currentMessage.text,
          chatOutputType: getQuestionType(progress),
          chatOutputOptionsCount: currentMessage.suggestions?.length ?? 0,
          chatOutputOptions: (currentMessage.suggestions ?? []).map(
            (suggestion) => suggestion.label,
          ),
          userInputSelectedCount:
            progress !== 'collectGeneralRequirementsAtLast'
              ? selectedSuggestions.length
              : undefined,
          userInputSelected: selectedSuggestions.map(
            (suggestion) => suggestion.label,
          ),
          userInputFreeText: isFreeText ? candidateMessage : undefined,
          userFeedback:
            currentSentiment !== null
              ? getFeedbackText(currentSentiment)
              : undefined,
        };

        analyticsFacade.homepageGenAIInputPressed(payload);

        return payload;
      }

      return null;
    },
    [
      analyticsFacade,
      currentMessage,
      currentSentiment,
      progress,
      sequence,
      trackingSessionId,
    ],
  );

  const trackGenAIChatInputSucceeded = useCallback(
    (args: Parameters<Analytics['homepageGenAIInputSucceeded']>[0]) => {
      analyticsFacade.homepageGenAIInputSucceeded(args);
    },
    [analyticsFacade],
  );
  const trackGenAIChatEnded = useCallback(
    (error?: boolean) => {
      analyticsFacade.homepageGenAIChatEnded({
        chatSessionId: trackingSessionId,
        lastOutputSequence: sequence,
        outputType: !error ? 'Recs Refresh Notice' : 'Error',
      });
    },
    [analyticsFacade, sequence, trackingSessionId],
  );

  const trackRecommendedJobsRefreshed = useCallback(
    ({ jobsCount }: { jobsCount: number }) => {
      analyticsFacade.homepageGenAIRecommendedJobsRefreshed({
        jobsCount,
      });
    },
    [analyticsFacade],
  );

  const trackGenAIChatResetPressed = useCallback(
    (buttonPosition: 'home recs' | 'chat widget') => {
      analyticsFacade.homepageGenAIChatActionPressed({
        action: 'reset',
        buttonPosition,
      });
    },
    [analyticsFacade],
  );

  return {
    trackGenAIChatCtaDisplayed,
    trackGenAIChatEnded,
    trackGenAIChatFeedbackPressed,
    trackGenAIChatInputPressed,
    trackGenAIChatInputSucceeded,
    trackGenAIChatOutputDisplayed,
    trackGenAIChatStarted,
    trackGenAIChatStartPressed,
    trackRecommendedJobsRefreshed,
    trackGenAIChatResetPressed,
  };
};

const getFeedbackText = (sentiment: boolean) =>
  sentiment ? 'Positive' : 'Negative';

const getQuestionType = (
  progress: ProgressType,
  error?: ErrorType,
): 'Last Question' | 'Question' | 'End Summary' | 'Error' => {
  if (error) {
    return 'Error';
  } else if (progress === 'collectGeneralRequirementsAtLast') {
    return 'Last Question';
  } else if (progress === 'reviewRefinement') {
    return 'End Summary';
  }

  return 'Question';
};
